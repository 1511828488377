'use strict';

function TermsConditionsCtrl(AgencyInfoService, $sce, $uibModalStack, _, $log) {
    "ngInject";
    const vm = this;
    vm.termsInfo = {
        info: []
    };

    vm.close = () => {
        $uibModalStack.dismissAll();
    };

    const defaultLegal = `<div>
   <p style='margin-bottom:0cm;text-align:center;
      line-height:normal;background:white'><span lang=EN-CA style='font-size:16px;font-weight:bold;
      font-family:Sans-serif;color:black'>App Terms of Use</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Please read these Terms of Use (the “Agreement”) as they will
      create a legal binding agreement between </span><span lang=EN-CA
         style='font-size:12.0pt;font-family:Sans-serif;color:black'>Trapeze
      Software Group, Inc. d.b.a TripSpark Technologies </span><span lang=EN-CA
         style='font-size:12.0pt;font-family:Sans-serif;color:black'>(“TripSpark”,
      “we” or “us”), and you when you click or tap “Agree” (or a similar term), or
      when you use the App (defined below).  This Agreement, and any applicable app
      store terms of use, will govern your use of this mobile application, the “Amble”
      app (“App”), and your use of the Services.  </span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Although we may attempt to notify you when major changes are made
      to this Agreement, you should periodically review the most up-to-date version. We
      may, in our sole discretion, modify or revise this Agreement and policies at
      any time, and you agree to be bound by such modifications or revisions. Nothing
      in this Agreement shall be deemed to confer any third party rights or benefits.
      </span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>To the extent permitted by applicable law, we may, at any time and
      without liability, modify or discontinue all or part of the Services, or offer
      opportunities to some or all users. We will seek to notify you by reasonable
      means of any modifications that will have a material adverse effect on the use
      of the Services, taken as a whole. We reserve the right to deny or terminate
      your access to the App, for any reason or no reason, provided such denial is
      not in violation of applicable law.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>YOU MUST BE, AND REPRESENT THAT YOU ARE, OLDER THAN THE AGE OF
      MAJORITY, OR HAVE THE EXPRESSED CONSENT OF YOUR LEGAL GUARDIAN, TO USE THE APP,
      THE SERVICES AND CONSENT TO THE COLLECTION AND USE OF PERSONAL DATA, IN YOUR
      JURISDICTION.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;margin-bottom:
      0cm;margin-left:18.0pt;text-indent:-18.0pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>Services Provided.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You recognize that the Services TripSpark provides do not include
      providing the actual Transit Services (defined below), which a provider of
      Transit Services (“Transit Provider”) provides you, according to terms of use
      you agree to with the Transit Provider. TripSpark does provide, via the App, the
      Transit Provider Selection Services and the Transit Service Facilitation Services
      (collectively the “Services”). </span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif'>1.1.<span
      style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span lang=EN-CA
      style='font-size:12.0pt;font-family:Sans-serif;color:black'>Transit
      Provider Selection Services. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:61.2pt;text-indent:-25.2pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.1.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>You acknowledge that any Transit Provider you select will be based
      on your expected use of Transit Services from that Transit Provider. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:61.2pt;text-indent:-25.2pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>TripSpark may present a list of Transit Providers (such as transit
      agencies). The list of Transit Providers may change, including Transit
      Providers being removed, with or without notice to you.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:61.2pt;text-indent:-25.2pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.1.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>You can change the Transit Provider using the App, for example if
      you are in a new city and wish to receive Transit Services from a different Transit
      Provider. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:72.0pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Facilitation of Transit Services.</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:61.2pt;text-indent:-25.2pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>The App allows you to request and receive various transit
      services, such as on demand transit or microtransit services (“Transit
      Services”) from the Transit Provider. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:61.2pt;text-indent:-25.2pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>Via the App you can specify a pick up location and a drop off
      location for a Transit Service, or the App can use the mobile device’s GPS
      receiver to detect your location for pick up. These locations are sent to the Transit
      Provider via the App as a request for Transit Services (“Request”). The Transit
      Provider can accept or reject each Request, at its sole discretion and subject
      to its own operating procedures (for example if the locations are within its
      areas of service). If the Transit Provider accepts the Request, the App will
      notify you and provide information regarding the Request, such as estimated
      pick up and drop off times. The App will also provide updates to view the Transit
      Provider's progress towards the pick-up location.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:61.2pt;text-indent:-25.2pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>TripSpark shall make reasonable efforts to provide your Request to
      the Transit Provider and receive any response the Transit Provider provides
      back to you, but provides no guarantee, express or implied, thereof.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:61.2pt;text-indent:-25.2pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>1.2.4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>For the avoidance of doubt, TripSpark does not provide Transit Services.
      The Transit Provider provides Transit Services, which may be requested through
      the App. TripSpark only acts as intermediary between you and the Transit
      Provider. The provision of the transportation services by the Transit Provider
      to you is therefore subject to any further agreement between you and the Transit
      Provider. TripSpark is not a party to such agreement.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>Your use of the App or the Service.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>By using the App or the Service, you agree that:</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.1.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You are responsible for downloading and installing the right App
      for your mobile device;</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>The information you provide via the App is accurate and complete,
      you will keep it updated, and agree that we are entitled to verify the
      information that you have provided and to refuse the Service or use of the App
      without providing reasons;</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Your use of the Service or App is only for your personal and
      non-commercial use within Canada and the United States (subject to available Transit
      Providers), and you will not give others access to the App, distribute the App,
      or assign the App to others;</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.4.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You will keep secure and confidential your account password or any
      identification we provide you which allows access to the Service and the App
      and you acknowledge that TripSpark will not be liable for any damages caused by
      your account or password being compromised;</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.5.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Your use of the Service or App will not be for unlawful or
      undesirable purposes (including but not limited to infringing a third party’s
      rights, obscene, threatening, libellous, or otherwise unlawful or tortious
      material, including material harmful to children or violative of third party
      privacy rights, sending or storing any unlawful material or for fraudulent
      purposes or cause annoyance or inconvenience to others) and you will comply
      with all applicable law where you use the App;</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.6.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You will not license, sublicense, modify or create derivative
      works of, sell or resell, transfer, assign, distribute or otherwise
      commercially exploit or make available to any third party the Service or App in
      any way;</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.7.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You have no rights in any content on the App (without limitation logos,
      icons, trademarks, text, graphics text, graphics, photographs, images, moving
      images, sound, illustrations, music, software, opinions, remarks, comments,
      artwork, links, questions, suggestions, information or other materials) other
      than pursuant to use of the App in accordance with this Agreement.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.8.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>TripSpark and the Transit Provider may use any content (including
      personal information or ideas about the App or Services) you provide via, or
      about, the App, subject to the Privacy Policy, and you confirm that doing so
      will not violate any laws of rights of third parties and assign all right title
      and interest in any ideas to TripSpark.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>2.9.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You will not reverse engineer or access the App in order to create
      a competitive product or service, or design or build a product using ideas or
      aspects of the Service or App, or copy or otherwise us any ideas, features,
      functions, graphics or other intellectual property of the Service or App, or launch
      an automated program or script, including, but not limited to, web spiders, web
      crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any
      program which may make multiple server requests per second, or unduly burdens
      or hinders the operation and/or performance of the Service or App or send or
      store material containing software viruses, worms, Trojan horses or other
      harmful computer code, files, scripts, agents or programs.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>Privacy.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>3.1.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>The App will request from you, and store (in the App and/or on
      TripSpark servers), certain personal information on your mobile device when you
      create your user profile, and when you access the App to request and receive
      Transit Services. Personal information may include your name, email, phone
      number, registered address, or other related information. We can use that personal
      information to provide the Services, to enable us to assist the Transit
      Provider to provide the Transit Services, to make improvements and analysis of
      such, and uses as may be described in the Privacy Policy (collectively the
      “Purposes”). The personal information may also be shared between TripSpark and
      the Transit Provider to further the Purposes. You should ensure that your
      mobile device is appropriately protected so that your stored details cannot be
      used or accessed by third parties. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>3.2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>TripSpark collects and processes your personal data according to
      the Privacy Policy located at [</span><span lang=EN-CA style='color:black'><a
         href="https://www.tripspark.com/privacy-policy"><span style='font-size:12.0pt;
         font-family:Sans-serif'>https://www.tripspark.com/privacy-policy</span></a></span><span
         lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>], which forms part of this Agreement.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:18.0pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>Liability and Indemnity.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>4.1.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Warranty Disclaimer.</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>YOUR USE OF THE APP IS AT YOUR SOLE RISK, TO THE EXTENT PERMITTED
      BY APPLICABLE LAW. WE, AND OUR EMPLOYEES, CONTRACTORS AND AGENTS DISCLAIM ALL
      WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE APP AND YOUR USE OF THE
      APP. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
      COMPLETENESS OF THE APP OR ANY CONTENT AND ASSUME NO LIABILITY OR
      RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES IN THE APP OR
      SERVICE, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
      RESULTING FROM YOUR ACCESS TO AND USE OF THE APP, (III) ANY UNAUTHORIZED ACCESS
      TO OR USE OF OUR SERVERS OR NETWORK AND/OR ANY AND ALL PERSONAL INFORMATION
      AND/OR STORED THEREON, (IV) ANY INTERRUPTION OR STOP OF COMMUNICATION WITH THE
      APP AND THE TRANSIT AGENCY, (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
      WHICH MAY BE TRANSMITTED TO OR THROUGH THE APP BY ANY THIRD PARTY, AND/OR (VI)
      ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND
      INCURRED AS A RESULT OF THE USE OF ANY CONTENT MADE AVAILABLE VIA THE APP. YOU
      SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION AT ALL TIMES WHEN USING THE
      APP OR THE SERVICES.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;margin-bottom:
      0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>4.2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Limitation of Liability.</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>IN NO EVENT SHALL TRIPSPARK, ITS OFFICERS, DIRECTORS, EMPLOYEES,
      OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
      PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER, WHETHER BASED ON WARRANTY,
      CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF
      LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
      JURISDICTION. IN NO EVENT SHALL TRIPSPARK’S LIABILITY TO YOU EXCEED $100 OR
      FEES PAID FOR THE TRANSIT SERVICE.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>4.3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Indemnity.</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You agree to defend, indemnify and hold harmless TripSpark, its
      officers, directors, employees and agents, from and against any and all claims,
      damages, obligations, losses, liabilities, costs or debt, and expenses
      (including but not limited to attorney’s fees) arising from: (i) your use of
      and access to the App or Services; or (ii) your violation of any term of this
      Agreement. This defense and indemnification obligation will survive this
      Agreement and your use of the App.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;margin-bottom:
      0cm;margin-left:18.0pt;text-indent:-18.0pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>App Store Terms of Use.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>If you are accessing the Service through an application store
      provided by a third-party such as Google, Inc. or Apple, Inc., then such third-party
      provider shall be a third-party beneficiary to this Agreement. These
      third-party beneficiaries are not responsible for the provision or support of
      the Services in any manner. In addition, you must comply with the terms of
      service of any such third-party beneficiary.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>If you obtain the App from the Apple app store, then the following
      terms apply to your use of the App, in addition to the Terms of Use herein:</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.1.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>If there are any conflicts between this Agreement and the Apple
      Store Terms of Service, the Apple Store Terms of Service will apply. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>The licence granted to you in this Agreement to use the App and
      Services is for use on Apple-branded products, owned or controlled by you. Your
      mobile device must be such a product. The App may be used or accessed by other
      accounts associated with you via Family Sharing or volume purchasing.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>We’re giving you personally the right to use the App and the
      Service as set out above. You can’t transfer the App or the Service to someone
      else, whether for money, for anything else or for free. And if you sell any
      device on which the App is installed, you must remove the App first. You must
      also keep all passwords secure and not provide this information to anyone else.
      </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.4.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>If you need any support with respect to the App, please contact
      us, not Apple as they are under no obligation to help with any support or
      maintenance questions arising out of use of the App. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.5.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>In the event of any failure of the App to conform to any
      applicable warranty, you may notify Apple, and Apple will refund the purchase
      price for the App to you; and that, to the maximum extent permitted by
      applicable law, Apple will have no other warranty obligation whatsoever with
      respect to the App, and any other claims, losses, liabilities, damages, costs
      or expenses attributable to any failure to conform to any warranty will be our
      sole responsibility.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.6.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>We are responsible for addressing any claims from you or any third
      party relating to the App or the end- user’s possession and/or use of the App,
      including, but not limited to: (i) product liability claims; (ii) any claim
      that the App fails to conform to any applicable legal or regulatory
      requirement; and (iii) claims arising under consumer protection, privacy, or
      similar legislation.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.7.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>In the event of any third party claim that the App or your
      possession and use of the App infringes that third party’s intellectual
      property rights, TripSpark or the Transit Provider, not Apple, will be solely
      responsible for the investigation, defence, settlement and discharge of any
      such intellectual property infringement claim.</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.8.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You represent and warrant that (i) you are not located in a
      country that is subject to a U.S. Government embargo, or that has been
      designated by the U.S. Government as a “terrorist supporting” country; and (ii)
      you are not listed on any U.S. Government list of prohibited or restricted
      parties.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;margin-bottom:
      0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>5.9.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>Apple, and Apple’s subsidiaries, are third party beneficiaries of this
      Agreement, and that, upon your acceptance of these Terms, Apple will have the
      right (and will be deemed to have accepted the right) to enforce this Agreement
      against you.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:18.0pt;text-indent:-18.0pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
      </span></span><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
         color:black'>General</span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.1.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>This Agreement, together with the Privacy Policy and any other
      legal notices published by TripSpark on the App, shall constitute the entire
      agreement between you and TripSpark concerning the App. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.2.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>If any provision of This Agreement is deemed invalid by a court of
      competent jurisdiction, the invalidity of such provision shall not affect the
      validity of the remaining provisions of this Agreement, which shall remain in
      full force and effect. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.3.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>No waiver of any term of this Agreement shall be deemed a further
      or continuing waiver of such term or any other term, and TripSpark’s failure to
      assert any right or provision under this Agreement shall not constitute a
      waiver of such right or provision. </span>
   </p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.4.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>The parties agree that any cause of action arising out of or
      related to the App or the Services must commence within one year after the
      cause of action accrues, otherwise such cause of action is permanently barred.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.5.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>TripSpark is entitled to terminate the Agreement at all times and
      with immediate effect (by disabling your use of the App and the Service) if you
      violate or breach any term of this Agreement, or in the opinion of TripSpark,
      misuse the App or the Service. TripSpark is not obliged to give notice of the
      termination of the Agreement in advance.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.6.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>The invalidity of any term of this Agreement shall not affect the
      validity of any other provision. If and to the extent that any provision of this
      Agreement is invalid, or is unacceptable in the given circumstances according
      to the criteria of reasonableness and fairness, a provision shall apply between
      the parties instead that is acceptable considering all the circumstances and
      which corresponds with the provisions of the void part as much as possible,
      taking into account the content and the purpose of this Agreement.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.7.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>TripSpark may give notice by means of a general notice on the
      Service or App, or by electronic mail to your email address on record in TripSpark's
      account information, or by written communication sent by regular mail to your
      address on record in TripSpark's account information.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.8.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>You may not assign your rights under these User Terms without
      prior written approval of TripSpark.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
   <p style='margin-top:0cm;margin-right:0cm;
      margin-bottom:0cm;margin-left:39.6pt;text-indent:-21.6pt;line-height:normal;
      background:white'><span lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>6.9.<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>These User Terms are subject to the laws of Ontario, Canada. Any
      dispute, claim or controversy arising out of or relating to this Agreement or
      the breach, termination, enforcement, interpretation or validity thereof or the
      use of the App or the Service will be settled exclusively by the competent
      court in Toronto, Ontario.</span>
   </p>
   <p style='margin-bottom:0cm;line-height:normal;background:white'><span
      lang=EN-CA style='font-size:12.0pt;font-family:Sans-serif;
      color:black'>&nbsp;</span></p>
</div>`;
    // Already filtered by today's date, browser language, and device type = browser
    // AgencyInfoService.getResources('terms').then(data => {
    //     if (data) {
    //         vm.termsInfo.info = _.map(data, function (entry) {
    //             return {
    //                 text: $sce.trustAsHtml(entry.text)
    //             };
    //         });
    //     } else {
vm.termsInfo.info = [{
    text: $sce.trustAsHtml(defaultLegal)
}];
    //     }
    // }, err => {
    //     $log.error(err);
    // });

}

export default {
    name: 'TermsConditionsCtrl',
    fn: TermsConditionsCtrl
};
