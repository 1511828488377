'use strict';

function DashboardCtrl($state, VersionInfoService, AgencyInfoService, ClientAppFeaturesService, $window, AgencyService,
    IEHelperService, ConfigService, RmxsService, JWTService, $log) {
    'ngInject';

    const vm = this;
    const AssetUrl = ConfigService.getAssetUrl();

    vm.images = [];
    vm.images.main_logo = `${AssetUrl}${AgencyInfoService.dashboard.main_logo}`;
    vm.images.main_text = AgencyInfoService.dashboard.main_text;

    vm.tileSet = [];
    vm.tileClassSet = [
        "dash-book-trip",
        "dash-trips",
        "dash-my-trips",
        "dash-account",
        "dash-logo",
        "dash-contacts",
        "dash-invisible",
        "dash-invisible"
    ];
    vm.brandedTile = {
        title: AgencyInfoService.dashboard.main_text,
        extras: {
            textClass: "dash-main-text"
        },
        function: {
            name: "toViewAbout",
            input: ""
        },
        logo: {
            src: `${AssetUrl}${AgencyInfoService.dashboard.main_logo}`,
            alt: "next trip logo"
        },
        appFeature: "",
        bussinessRule: ""
    };
    vm.priorityTiles = [
        {
            title: "BOOK A TRIP",
            function: {
                name: "toBookTrip",
                input: ""
            },
            logo: {
                src: "images/dash-book-trip.png",
                alt: "book trip logo"
            },
            appFeature: "amble_book_a_trip",
            bussinessRule: "allowTripBooking"
        },
        {
            title: "BUY PASSES",
            function: {
                name: "toPayPortal",
                input: "/pay/amble/buy-passes"
            },
            logo: {
                src: "images/pass-large.svg",
                alt: "passes logo"
            },
            appFeature: "pay_rider_buy_pass",
            bussinessRule: ""
        },
        {
            title: "NEXT TRIP",
            function: {
                name: "toViewNextTrip",
                input: ""
            },
            logo: {
                src: "images/dash-trips.png",
                alt: "next trip logo"
            },
            appFeature: "amble_next_trip",
            bussinessRule: "viewTripDisable"
        },
        {
            title: "MY TRIPS",
            function: {
                name: "toViewTrips",
                input: ""
            },
            logo: {
                src: "images/ic-map@3x.png",
                alt: "my trip logo"
            },
            appFeature: "amble_my_trips",
            bussinessRule: "viewTripDisable"
        },
        {
            title: "MY ACCOUNT",
            function: {
                name: "toViewMyAccount",
                input: ""
            },
            logo: {
                src: "images/dash-account.png",
                alt: "my account logo"
            },
            appFeature: "amble_my_profile",
            bussinessRule: ""
        },
        {
            title: "MY PAYMENTS",
            function: {
                name: "toPayPortal",
                input: "/pay/amble/my-payments/"
            },
            logo: {
                src: "images/page-1@3x.png",
                alt: "payment logo"
            },
            appFeature: "pay_my_payments",
            bussinessRule: ""
        }
    ];
    vm.fillerTiles = [
        {
            title: "CONTACT",
            function: {
                name: "toViewContacts",
                input: ""
            },
            logo: {
                src: "images/dash-contacts.png",
                alt: "contact logo"
            }
        },
        {
            title: "ABOUT",
            function: {
                name: "toViewAbout",
                input: ""
            },
            logo: {
                src: "images/group-4@3x.png",
                alt: "About Us logo"
            }
        },
        {
            title: "Policies",
            function: {
                name: "toViewPolicies",
                input: ""
            },
            logo: {
                src: "images/dash-policies.png",
                alt: "policies logo"
            }
        },
        {
            title: "Legal Information",
            function: {
                name: "toViewTerms",
                input: ""
            },
            logo: {
                src: "images/group-3@3x.png",
                alt: "terms logo"
            }
        }
    ];
    const appFeatures = [];

    vm.versioninfo = {
        version: VersionInfoService.version,
        nickname: VersionInfoService.nickname
    };

    const agency = AgencyService.getAgency();

    const setupTiles = () => {
        // Compare App Features With Priority Tiles
        const tileMinLength = 5;
        vm.priorityTiles.forEach((tile) => {
            if (IEHelperService.findInArray(appFeatures, null, tile.appFeature) > -1) {
                vm.tileSet.push(tile);
            }
        });
        // Enforce Business Rules
        if (!vm.allowTripBooking) {
            const indexOfTile = IEHelperService.findInArray(vm.tileSet, 'bussinessRule', 'allowTripBooking'); //vm.tileSet.findIndex(item => item.bussinessRule === 'allowTripBooking');
            if (indexOfTile > -1) {
                vm.tileSet.splice(indexOfTile, 1);
            }
        }
        if (vm.viewTripDisable) {
            let indexOfTile = IEHelperService.findInArray(vm.tileSet, 'bussinessRule', 'viewTripDisable'); //vm.tileSet.findIndex(item => item.bussinessRule === 'viewTripDisable');
            while (indexOfTile > -1) {
                vm.tileSet.splice(indexOfTile, 1);
                indexOfTile = IEHelperService.findInArray(vm.tileSet, 'bussinessRule', 'viewTripDisable'); //vm.tileSet.findIndex(item => item.bussinessRule === 'viewTripDisable');
            }
        }

        // Fill The Gaps
        if (tileMinLength > vm.tileSet.length) {
            const toAdd = tileMinLength - vm.tileSet.length;
            for (let index = 0; index < toAdd; index++) {
                vm.tileSet.push(vm.fillerTiles[index]);
            }
        }
        // Force Middle Branded Tile
        vm.tileSet.splice(4, 0, vm.brandedTile);
    };

    // Private Controller Functions
    const getAppFeatures = () => {
        ClientAppFeaturesService.getClientAppFeatures('browser', 'amble', '1.0.0', agency).then((data) => {
            data.features.forEach(feature => {
                appFeatures.push(feature.name);
            });
            setupTiles();
        });
    };

    const getBookingTripRules = () => {
        return AgencyInfoService.bookTripRules().then((data) => {
            const bookTripRules = data[0].rules;
            bookTripRules.forEach(item => {
                const rule = item.rule;
                if (rule.meta.identity === "ALLOW-TRIP-BOOKING") {
                    vm.allowTripBooking = rule.rule.allowTripBooking;
                }
                if (rule.meta.identity === "VIEW-TRIP-DISABLE") {
                    vm.viewTripDisable = rule.rule.viewTripDisable;
                }
            });

            //RID-2795: Only provide the book a trip functionality if the settings are adhered to (Rider Portal)
            return RmxsService.getDefaultServiceInfo(JWTService.getRmid())
                .catch( (err) => {
                    $log.error('Error setting book trip behavorior:', err);
                    return {};
                });
        }).then((data) => {
            const { AmbleBookingBehavior = null } = data;

            // regardless of business rule, we need to check what booking behavior is
            if (AmbleBookingBehavior === 4) {
                vm.allowTripBooking = false;
            }

            getAppFeatures();
        }).catch(err => {
            $log.error('Error setting book trip behavorior:', err);
        });
    };

    // Run On Start
    getBookingTripRules();

    // Public Controller Functions
    vm.toBookTrip = () => {
        $state.go('Rider.BookTrip');
    };

    vm.toViewTrips = () => {
        $state.go('Rider.listTrip');
    };

    vm.toViewMyAccount = () => {
        $state.go('Rider.myAccount');
    };

    vm.toViewAbout = () => {
        $state.go('Rider.about');
    };

    vm.toViewTerms = () => {
        $state.go('Rider.terms');
    };

    vm.toViewPolicies = () => {
        $state.go('Rider.policies');
    };

    vm.toViewContacts = () => {
        $state.go('Rider.contact');
    };

    vm.toPayPortal = (uri) => {
        $window.location.href = uri;
    };

    vm.toViewNextTrip = () => {
        $state.go('Rider.viewNextTrip');
    };

}
export default {
    name: 'DashboardCtrl',
    fn: DashboardCtrl
};
