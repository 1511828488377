'use strict';

function ViewTripCtrl($stateParams, $scope, $window, $log, RmxsService, JWTService, _, moment, TripPlanningService, BookingService, $state, TripsService, $uibModal, AgencyInfoService, AgencyService, TripDetailsUtils, ClientAppFeaturesService) {
    'ngInject';

    const vm = this;
    let trip = {};
    const mobilityTypeList = {};
    const mobilityTypes = {
        attendant: null,
        guest: null,
        cust: null
    };
    const journeyId = $stateParams.journeyid;
    vm.showMessage = false;
    vm.tripInfo = {
        tripDate: null,
        departAddr: null,
        destAddr: null,
        departTime: null,
        destTime: null,
        tripPurposeId: null,
        tripPurpose: null,
        status: null,
        // RID-2592 - START
        statusString: null,
        statusColor: null,
        statusMessage: null,
        statusIcon: null
        // RID-2592 - END
    };

    const getTripPurposes = () => {
        RmxsService.getTripPurpose().then((trippurposes) => {
            if (vm.tripInfo.tripPurposeId !== null && vm.tripInfo.tripPurposeId !== 0) {
                const tripPurpose = trippurposes.filter((tp) => {
                    return tp.tripPurposeId === vm.tripInfo.tripPurposeId;
                });
                vm.tripInfo.tripPurpose = tripPurpose[0].tripPurposeType;
            } else {
                vm.tripInfo.tripPurpose = null;
            }
        });
    };

    const getMobilityTypes = () => {
        vm.mobilityDevices = [];

        if (trip.attendcount > 0 && trip.guestcount > 0) {

            if (_.isEqual(trip.custmobid, trip.guestmobid) && _.isEqual(trip.custmobid, trip.attendmobid)) {
                vm.mobilityDevices.push({
                    'count': (1 + trip.guestcount + trip.attendcount),
                    'type': mobilityTypes.attendant
                });
            } else if (_.isEqual(trip.custmobid, trip.attendmobid)) {
                vm.mobilityDevices.push({
                    'count': (1 + trip.attendcount),
                    'type': mobilityTypes.attendant
                }, {
                    'count': (trip.guestcount),
                    'type': mobilityTypes.guest
                });
            } else if (_.isEqual(trip.custmobid, trip.guestmobid)) {
                vm.mobilityDevices.push({
                    'count': (1 + trip.guestcount),
                    'type': mobilityTypes.guest
                }, {
                    'count': (trip.attendcount),
                    'type': mobilityTypes.attendant
                });
            } else if (_.isEqual(trip.attendmobid, trip.guestmobid)) {
                vm.mobilityDevices.push({
                    'count': (trip.attendcount + trip.guestcount),
                    'type': mobilityTypes.guest
                }, {
                    'count': 1,
                    'type': mobilityTypes.cust
                });
            } else {
                vm.mobilityDevices.push({
                    'count': trip.attendantCount,
                    'type': mobilityTypes.attendant
                }, {
                    'count': trip.guestcount,
                    'type': mobilityTypes.guest
                }, {
                    'count': 1,
                    'type': mobilityTypes.cust
                });
            }
        } else if (_.isNil(trip.attendcount) && _.isNil(trip.guestcount)) {
            vm.mobilityDevices.push({
                'count': 1,
                'type': mobilityTypes.cust
            });
        } else {
            if (trip.attendcount > 0) {
                vm.mobilityDevices.push({
                    'count': trip.attendcount,
                    'type': mobilityTypes.attendant
                }, {
                    'count': 1,
                    'type': mobilityTypes.cust
                });
            } else if (trip.guestcount > 0) {
                vm.mobilityDevices.push({
                    'count': trip.guestcount,
                    'type': mobilityTypes.guest
                }, {
                    'count': 1,
                    'type': mobilityTypes.cust
                });
            }
        }
        getTripPurposes();
    };


    const getMobilityTypeList = () => {
        RmxsService.getMobilityTypeList().then((types) => {
            types.map(function (type) {
                mobilityTypeList[type.mobilityRequirementId] = type.mobilityRequirementType;
            });
            if (!_.isNil(trip.attendcount)) {
                vm.tripInfo.attendantCount = trip.attendcount;
                mobilityTypes.attendant = mobilityTypeList[trip.attendmobid];
            } else {
                vm.tripInfo.attendantCount = 0;
            }

            if (!_.isNil(trip.guestcount)) {
                vm.tripInfo.guestCount = trip.guestcount;
                mobilityTypes.guest = mobilityTypeList[trip.guestmobid];
            } else {
                vm.tripInfo.guestCount = 0;
            }
            if (!_.isNil(trip.custid)) {
                mobilityTypes.cust = mobilityTypeList[trip.custmobid];
            }

            getMobilityTypes();
        });
    };

    // RID-2592 / RID-85: As a rider, I should see my negotiated requested pickup/initial requested pickup, or scheduled time window displayed in the trip details of my trip, so I will be ready on time (Rider Portal)
    const getTripDetail = () => {
        vm.businessRules = {};
        // Need to get all applicable business rules to be able to use them
        AgencyInfoService.bookTripRules().then((data) => {
            const bookTripRules = data[0].rules;
            bookTripRules.filter(({ rule: { meta: { identity } } }) => {
                return _.includes(TripDetailsUtils.applicableRules, identity);
            }).forEach(({ rule: { rule: applicableRule } }) => {
                Object.keys(applicableRule)
                    .forEach(key => {
                        vm.businessRules[key] = applicableRule[key];
                    });
            });
        }).then(() => {
            const tripBegin = moment(trip.steps[0].departTime);
            vm.tripInfo.tripDate = moment(trip.steps[0].tripDate).format('MMMM Do YYYY');
            vm.tripInfo.departAddr = trip.steps[0].pickup.name;
            vm.tripInfo.destAddr = trip.steps[trip.steps.length - 1].dropoff.name;

            // RID-85: Add new properties and dynamically update them - START
            vm.tripInfo.computedBaseTime = trip.steps[0].scheduleTimeBasis ? trip.steps[0].scheduleTimeBasis : null;
            vm.tripInfo.computedDepartTimeLate = trip.steps[0].departTimeLate ? trip.steps[0].departTimeLate : null;
            vm.tripInfo.computedDepartTimeEarly = trip.steps[0].departTimeEarly ? trip.steps[0].departTimeEarly : null;
            vm.tripInfo.isPassengerOnboard = trip.steps[0].isPassengerOnboard;
            vm.tripInfo.status = (vm.tripInfo.isPassengerOnboard) ? 6 : trip.steps[0].status;
            vm.tripInfo.isADA = trip.steps[0].isADA;
            vm.tripInfo.isPickup = (trip.steps[0].timingPreference === "P");
            vm.tripInfo.destTime = moment(trip.steps[trip.steps.length - 1].arrivalTime).format('LT'); //destTime = arrivalTime
            vm.tripInfo.departTime = moment(trip.steps[0].departTime).format('LT');
            vm.tripInfo.tripPurposeId = trip.trippurposeid ? trip.trippurposeid : null;
            vm.tripInfo.destinationphone = trip.destinationphone ? trip.destinationphone : null;
            const now = moment();

            // RID-2681: User is able to cancel a trip with CANCEL-TRIP-DISABLE business rule set to true (Portal)
            // AND
            // RID-2982: If the driver is 1 minute late, the trip is displaying as a cancelled trip - need to handle with business rule
            // AND
            // RID-3190: small bug where if the status of the trip is 4 (cancelled) do not show cancel button
            const isTripWithinScheduledThreshold = TripDetailsUtils.isTripWithinScheduledThreshold(trip.steps[0].departTime,
                vm.businessRules.schedTripsThresholdMinutes);

            const isTripCancellable = TripDetailsUtils.isCancellable(trip.steps[0].departTime, trip.steps[trip.steps.length - 1].arrivalTime,
                vm.businessRules.minutesRestrictCancel);  // checks for arrival time multi step trips as well

            vm.showCancelTripButton = !vm.businessRules.cancelTripDisable && isTripWithinScheduledThreshold && vm.tripInfo.status !== 4 && isTripCancellable;

            if (vm.tripInfo.status !== 3 && vm.tripInfo.status !== 4 && tripBegin > now) {
                vm.showMessage = true;
            }

            getMobilityTypeList(trip, mobilityTypeList, mobilityTypes);

            vm.tripInfo.steps = trip.steps;

            // One merge of new/dynamic values into this 'vm' objects
            const updatedPropertiesToMerge = TripDetailsUtils.calculateTimeToDisplay(trip, vm);
            Object.assign(vm.tripInfo, updatedPropertiesToMerge);
            // RID-85: Add new properties and dynamically update them - END

            // RID-3042: Amble should not display the trip itinerary if the agency is not multi-modal
            return ClientAppFeaturesService
                .getClientAppFeatures('browser', 'amble', '1.0.0', AgencyService.getAgency());
        }).then(({ features: clientAppFeatures }) => {
            $log.debug(`Features found in DB: ${angular.toJson(clientAppFeatures)}`);
            vm.showTripItinerary = false; //default
            vm.showTripItinerary = clientAppFeatures.some(({ name }) => name === 'amble_show_itinerary');
        }).catch(function (err) {
            $log.error(err);
            vm.errorMessage = err;
        });
    };

    const journeyLoad = () => {
        const journeyId = $stateParams.journeyid;
        const billInfo = true;
        TripsService.getSpecJourney(journeyId, billInfo)
            .then(function (journBreakdown) {
                trip = journBreakdown.journey;
                return null;
            })
            .then(() => {
                getTripDetail();
                return null;
            })
            .catch(function (err) {
                vm.errorMessage = err;
            });
    };
    journeyLoad();

    const openResultModal = (message, imgAdrr) => {
        vm.resultMessage = message;
        $scope.resultImage = imgAdrr;
        vm.modalInstanceResult = $uibModal.open({
            templateUrl: 'modals/modal-cancel-journey-outcome.html',
            scope: $scope
        });
    };

    const cancelTrip = () => {
        vm.resultMessage = "";
        TripsService.cancelJourney(parseInt(journeyId))
            .then(() => {
                openResultModal("The journey has been canceled", "../../images/nav_icons/success-mark@3x.png");
                vm.tripInfo.status = 4;
            })
            .catch((err) => {
                vm.errorMessage = err;
                openResultModal("There has been an error", "../../images/header/failure_icon.png");
            });
    };

    const closeModal = () => {
        vm.modalInstance.dismiss();
    };

    vm.exitConfirmModal = () => {
        vm.modalInstanceResult.dismiss();
        $window.location.reload();
    };

    vm.cancelTripModalConfirm = () => {
        closeModal();
        cancelTrip();
    };

    const openCancelJourneyModal = () => {
        vm.modalInstance = $uibModal.open({
            templateUrl: 'modals/modal-cancel-journey.html',
            scope: $scope
        });
    };

    vm.cancelTrip = () => {
        openCancelJourneyModal();
    };

    vm.closeModalWindow = () => {
        closeModal();
    };
}

export default {
    name: 'ViewTripCtrl',
    fn: ViewTripCtrl
};
