'use strict';

function MenuFactory($rootScope, $location, $q, AgencyInfoService, AgencyService, ClientAppFeaturesService, _, IEHelperService, $log, RmxsService, JWTService) {
    'ngInject';

    const factory = {};
    let allowTripBooking = false;
    let viewTripDisable = false;
    let sections = [];
    const agency = $location.search().a ? $location.search().a : AgencyService.getAgency();

    const applyAppFeaturesToMenu = (menu, appFeatures) => {
        return $q(function (resolve) {
            menu.forEach((menuItem, menuIndex) => {
                if (IEHelperService.findInArray(appFeatures, 'name', menuItem.feature) > -1) {
                    if (menuItem.name === 'Book a Trip' && !allowTripBooking) {
                        menu[menuIndex].enabled = false;
                    } else if (menuItem.name === 'My Trips' && viewTripDisable) {
                        menu[menuIndex].enabled = false;
                    } else {
                        menu[menuIndex].enabled = true;
                    }
                } else {
                    if (menuItem.pages) {
                        let subMenuIndex = menuItem.pages.length - 1;
                        for (subMenuIndex; subMenuIndex >= 0; subMenuIndex--) {
                            if (IEHelperService.findInArray(appFeatures, 'name', menuItem.pages[subMenuIndex].feature) > -1) {
                                menuItem.pages[subMenuIndex].enabled = true;
                            }
                        }
                        if (IEHelperService.findInArray(menuItem.pages, 'enabled', true) > -1) {
                            menu[menuIndex].enabled = true;
                        }
                    }
                }
            });
            resolve(menu);
        });
    };

    sections.push(
        // {
        //   name: 'Search',
        //   cls: 'menu-icon-search',
        //   type: 'link',
        //   state: 'Rider.search',
        //   icon: '',
        //   aria: 'Search',
        //   feature: 'amble_search',
        //   enabled: false
        // },
        {
            name: 'Home',
            cls: 'menu-icon-home',
            type: 'link',
            state: 'Rider.home',
            icon: 'images/nav_icons/home-icon.png',
            aria: 'Homepage',
            feature: 'amble_home',
            enabled: false
        },
        {
            name: 'Book a Trip',
            cls: 'menu-icon-book-trip',
            type: 'link',
            state: 'Rider.BookTrip',
            icon: 'images/nav_icons/bookat-trip-icon.png',
            aria: 'Book a Trip',
            feature: 'amble_book_a_trip',
            enabled: false
        },
        {
            name: 'Next Trip',
            cls: 'menu-icon-next-trip',
            type: 'link',
            state: 'Rider.viewNextTrip',
            icon: 'images/nav_icons/next-trip-white.svg',
            aria: 'Next Trip',
            feature: 'amble_next_trip',
            enabled: false
        },
        {
            name: 'My Trips',
            cls: 'menu-icon-my-trips',
            type: 'link',
            state: 'Rider.listTrip',
            icon: 'images/ic-map@3x.png',
            aria: 'My Trips',
            feature: 'amble_my_trips',
            enabled: false
        },
        // {
        //   name: 'Notifications',
        //   cls: 'menu-icon-notifications',
        //   type: 'link',
        //   state: 'Rider.notifications',
        //   icon: '',
        //   aria: 'Notifications',
        //   feature: 'amble_notifications',
        //   enabled: false
        // },
        {
            name: 'My Account',
            cls: 'menu-icon-my-account',
            type: 'link',
            state: 'Rider.myAccount',
            icon: 'images/nav_icons/profile-icon.png',
            aria: 'My Account',
            feature: 'amble_my_profile',
            enabled: false
        },
        {
            name: '',
            cls: 'menu-icon-my-payments',
            type: 'toggle',
            state: '/pay/amble/my-payments/',
            icon: '',
            aria: 'My Payments',
            enabled: false,
            pages: [{
                name: 'My Payments',
                cls: 'menu-icon-my-payments',
                type: 'uri',
                state: '/pay/amble/my-payments/',
                icon: '',
                aria: 'My Payments',
                feature: 'pay_my_payments',
                enabled: false
            }, {
                name: 'Payment Methods',
                cls: 'menu-icon-my-payments',
                type: 'uri',
                state: '/pay/amble/payment-methods',
                icon: '',
                aria: 'Payment Methods',
                feature: 'pay_payment_methods',
                enabled: false
            }
            ]
        },
        {
            name: '',
            cls: 'menu-icon-buy-pass',
            type: 'toggle',
            state: '/pay/amble/buy-passes',
            icon: '',
            aria: 'Buy Passes',
            enabled: false,
            pages: [{
                name: 'Buy Passes',
                cls: 'menu-icon-buy-pass',
                type: 'uri',
                state: '/pay/amble/buy-passes',
                icon: '',
                aria: 'Buy Passes',
                feature: 'pay_rider_buy_pass',
                enabled: false
            },
            {
                name: 'My Passes',
                cls: 'menu-icon-my-payments',
                type: 'uri',
                state: '/pay/amble/my-passes',
                icon: '',
                aria: 'My Passes',
                feature: 'pay_rider_view_pass',
                enabled: false
            }
            ]
        },
        {
            name: 'More',
            cls: 'menu-icon-more',
            type: 'toggle',
            icon: 'images/nav_icons/more-icon.png',
            aria: 'More Options',
            enabled: false,
            pages: [{
                name: 'About',
                cls: 'menu-icon-about',
                type: 'link',
                state: 'Rider.about',
                icon: 'images/nav_icons/more-icon.png',
                aria: 'About',
                feature: 'amble_about',
                enabled: false
            }, {
                name: 'Legal Information',
                cls: 'menu-icon-terms',
                type: 'link',
                state: 'Rider.terms',
                icon: 'images/nav_icons/more-icon.png',
                aria: 'Terms',
                feature: 'amble_terms',
                enabled: false
            }
            // {
            //     name: 'Privacy Policy',
            //     cls: 'menu-icon-policies',
            //     type: 'link',
            //     state: 'Rider.policies',
            //     icon: 'images/nav_icons/more-icon.png',
            //     aria: 'Policies',
            //     feature: 'amble_privacy',
            //     enabled: false
            // }
            ]
        },
        // {
        //   name: 'Settings',
        //   cls: 'menu-icon-settings',
        //   type: 'link',
        //   state: 'Rider.settings',
        //   icon: '',
        //   aria: 'Settings',
        //   feature: 'amble_settings',
        //   enabled: false
        // },
        {
            name: 'Contact',
            cls: 'menu-icon-contact',
            type: 'link',
            state: 'Rider.contact',
            icon: 'images/nav_icons/more-icon.png',
            aria: 'Contact',
            feature: 'amble_contact',
            enabled: false
        },

        {
            name: 'Logout',
            cls: 'menu-icon-logout',
            type: 'function',
            state: '',
            icon: 'images/nav_icons/more-icon.png',
            aria: 'Logouts',
            feature: '',
            enabled: true
        }

    );

    factory.setOpenedSection = (section) => {
        if (angular.isDefined(section)) {
            factory.openedSection = section;
        } else {
            const sections = factory.sections;
            let pathname = location.pathname.toLocaleLowerCase();
            let linkState;
            pathname = pathname.substr(1, pathname.length);
            for (let section = 0; section < sections.length; section++) {
                linkState = sections[section].state;
                if (angular.isDefined(sections[section].pages)) {
                    for (let page = 0; page < sections[section].pages.length; page++) {
                        linkState = sections[section].pages[page].state;
                        if (angular.isDefined(linkState) && linkState.toLocaleLowerCase().indexOf(pathname) !== -1) {
                            factory.openedSection = sections[section];
                            break;
                        }
                    }
                } else {
                    if (angular.isDefined(linkState) && linkState.toLocaleLowerCase().indexOf(pathname) !== -1) {
                        factory.openedSection = sections[section];
                        break;
                    }
                }
            }
        }
    };

    //init the current active menu
    factory.toggleSelectSection = (section) => {
        factory.openedSection = (factory.openedSection === section ? null : section);
    };

    factory.isSectionSelected = (section) => {
        return factory.openedSection === section;
    };

    factory.selectedSection = () => {
        return factory.openedSection;
    };

    factory.selectPage = (section, page) => {
        factory.currentSection = section;
        factory.currentPage = page;
    };

    factory.loadTripRule = () => {
        AgencyInfoService.bookTripRules().then((data) => {
            if (!_.isEmpty(data)) {
                const bookTripRules = data[0].rules;
                for (let i = 0; i < bookTripRules.length; i++) {
                    const index = bookTripRules[i].rule.rule;
                    switch (bookTripRules[i].rule.meta.identity) {
                    case "ALLOW-TRIP-BOOKING":
                        allowTripBooking = index.allowTripBooking;
                        break;
                    case "VIEW-TRIP-DISABLE":
                        viewTripDisable = index.viewTripDisable;
                        break;
                    }
                }
            }

            // RID-2795: Only provide the book a trip functionality if the settings are adhered to (Rider Portal)
            return RmxsService.getDefaultServiceInfo(JWTService.getRmid())
                .catch( (err) => {
                    $log.error('Error setting book trip behavorior:', err);
                    return {};
                });
        }).then((data) => {
            const { AmbleBookingBehavior = null } = data;

            // regardless of business rule, we need to check what booking behavior is
            if (AmbleBookingBehavior === 4) {
                allowTripBooking = false;
            }

            return ClientAppFeaturesService.getClientAppFeatures('browser', 'amble', '1.0.0', agency);
        }).then((data) => {
            return applyAppFeaturesToMenu(sections, data.features);
        }).then((data) => {
            sections = data;
            factory.sections = sections;
            factory.setOpenedSection();
        }).catch(err => {
            $log.error('Error setting book trip behavior:', err);
        });
    };

    factory.sections = sections;
    factory.setOpenedSection();
    return factory;
}

export default {
    name: 'menu',
    fn: MenuFactory
};
